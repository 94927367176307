"use client"; // Error components must be Client Components

import React from "react";

import BugSnagNotification from "@ng-components/error-page/BugSnagNotification";
import Actions from "@ng-components/actions/Actions";
import Button from "@ng-components/actions/Button";
import ErrorPageAnimation from "@ng-components/error-page/ErrorPageAnimation";
import ErrorPageDescription from "@ng-components/error-page/ErrorPageDescription";
import ErrorPageSection from "@ng-components/error-page/ErrorPageSection";
import ErrorPageTitle from "@ng-components/error-page/ErrorPageTitle";

export default function Error({error}: {error: Error}) {
    return (
        <>
            <BugSnagNotification error={error} errorBoundary="root" />
            <ErrorPageSection>
                <ErrorPageAnimation fileUrl="/assets/error-animation.mp4" />
                <ErrorPageTitle>Le bateau prend l’eau...</ErrorPageTitle>
                <ErrorPageDescription>
                    Un problème technique a eu lieu. Nos équipes sont sur le pont !
                </ErrorPageDescription>
                <Actions justify="center">
                    <Button text="Naviguer vers la terre ferme" url="/" />
                </Actions>
            </ErrorPageSection>
        </>
    );
}
